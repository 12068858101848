var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Center"},[_c('nav-vue'),_c('div',{staticClass:"banner"},[_c('img',{staticClass:"banner__img",attrs:{"src":_vm.config.school_banner,"alt":""}})]),(!_vm.isphone)?_c('img',{staticClass:"content__could",attrs:{"src":require("@/assets/image/rd/could.png"),"alt":""}}):_vm._e(),(!_vm.isphone)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__box"},[_c('ul',{staticClass:"content__box__left"},_vm._l((_vm.schoolData),function(item,index){return _c('li',{key:index,class:{
              'content__box__left-item': true,
              active: _vm.listActive === index,
            },on:{"click":function($event){return _vm.Onlist(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('img',{staticClass:"content__lone",attrs:{"src":require("@/assets/image/rd/lines.png")}}),(_vm.schoolData != null)?_c('div',{staticClass:"content__box__right",domProps:{"innerHTML":_vm._s(_vm.schoolData[_vm.listActive].content)}}):_vm._e()])]):_c('div',{staticClass:"content"},[_c('div',{staticClass:"content__box"},[_c('ul',{staticClass:"content__box__title"},_vm._l((_vm.schoolData),function(item,index){return _c('li',{key:index,class:{
              'content__box__title-item': true,
              active: _vm.listActive === index,
            },on:{"click":function($event){return _vm.Onlist(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),(_vm.schoolData != null)?_c('div',{staticClass:"content__box__right",domProps:{"innerHTML":_vm._s(_vm.schoolData[_vm.listActive].content)}}):_vm._e()])]),_c('footer-vue')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }